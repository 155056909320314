.data-container {
    position: relative; /* Keep this for positioning */
    overflow: hidden; /* Prevent overflow */
}

.data-container img {
    aspect-ratio: 3/2;
    object-fit: cover;
    width: 100%; 
    height: auto; 
}

.data-container::after{
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    bottom: 0;
    content: "";

}

.data-container h3 {
  

    /* background: rgba(0, 0, 0, 0.6); */
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top:50%;
    display: block;
    font-size: 14px;
    color: white;
    margin: 0;
    padding: 4px 0;
    font-weight: bolder;
    z-index: 1;
    text-align: center;
    width: 100%; 
     /* bottom: 0; */
}
