.postdropdown{
    width: 200px;
    margin: 2px !important;
}

@media only screen and (min-width: 769px) and (max-width : 992px){
    .postdropdown{
        width: 70% !important;
        margin: 2px !important;
    }    
}

@media only screen and (min-width: 601px) and (max-width : 768px){
    .postdropdown{
        width: 70% !important;
        margin: 2px !important;
    }    
}

@media only screen and (max-width: 600px){
    .postdropdown{
        width: 100% !important;
        margin: 2px !important;
    }    
}



