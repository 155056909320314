/*Starting ICons*/
.contentcontainer {
  padding-top: 40px;
}
.icons {
  border-bottom: 2px solid #000000;
  padding: 40px;
  background-color: lightgrey;
}
#homes h4 {
  margin-top: 60px;
}

/*Homes Container*/
.homes-container {
  /* background:url('../../Assets/images/lenders.jpg')  ;     */
  max-height: 800px;
  background-position: right;
  background-repeat: no-repeat;
}

.section-white {
  font-family: Karla-Regular;
  background-color: whitesmoke;
  padding-top: 40px;
  padding-bottom: 40px;
}
.section-white {
  padding-top: 60px;
  padding-bottom: 20px;
}
/* manufacturing-container */
.manufacturing-container {
  background-repeat: no-repeat;
  max-height: 800px;
}
/*buildings-container*/
.buildings-container {
  max-height: 800px;
  background-position: right;
  background-repeat: no-repeat;
}

/*smartcity-container*/
.smartcity-container {
  max-height: 800px;
  background-repeat: no-repeat;
}
/*consumer-container*/
.consumer-container {
  max-height: 800px;
  background-position: right;
  background-repeat: no-repeat;
}
/*semiconductors-container*/
.semiconductors-container {
  max-height: 800px;
  background-repeat: no-repeat;
}
/*oilandgas-container*/
.oilandgas-container {
  max-height: 800px;
  background-position: right;
  background-repeat: no-repeat;
}

.imgContainer {
  padding-right: 0px;
  padding-left: 0px;
}
.businessPartnerContainer {
  background-attachment: fixed;
  max-width: calc(100%+0px);
  margin: 0px;
  min-height: 100vh;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-image: 
    url("../../images/partner3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  padding-top: 0px;
}
.closeDiv {
  float: right;
  /* margin-right: -22px; */
  color: #fff;
  padding: 7px 8px 2px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%);
}
.closeDiv i {
  color: white;
  font-weight: 500;
}
.gridSection {
  background: rgba(12, 12, 12, 0.4);
  display: inline-block;
  position: relative;
  color: white;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 20px 40px;
}
.gridSection img {
  cursor: pointer;
  height: 64px !important;
  width: 64px !important;
  margin-bottom: 8px;
}
.gridSection .hoverEffect:hover {
  background: rgba(33, 33, 33, 0.4);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 3px 1px -2px rgb(0 0 0 / 12%);
}
.gridSection a {
  text-decoration: none !important;
}
.gridSection h4 {
  font-weight: 400;
  color: whitesmoke;
}
.fullWidthContainer {
  min-width: calc(100% + 0px) !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  /* text-align: center; */
}
.registerBtn--mod {
  margin-top: 20px !important;
  color: #fff !important;
}

.registerBtn--mod a {
  text-decoration: none;
  color: white;
  line-height: 25px;
}
.contentContainer {
  text-align: center;
  padding: 50px 50px 0px 50px !important;
}
.section-inner-border {
  color: rgba(49, 207, 160, 0.87);
}

.apps-section img{
  width: 100% !important;
  height: auto !important;
}
.imgContainer {
  padding: 0px !important;
}
.business-partner-section{
  padding: 120px 0 60px;
  background-position: initial;
}
.sectionWhite {
  background-color: #fff;
}
@media screen and (max-width: 639px) {
  .businessPartnerContainer {
    display: block;
    padding: 80px 0 40px;
    min-height: auto;
  }
  .gridSection {
    background-color: rgba(12, 12, 12, 0.4) !important;
  }
  .closeDiv {
    padding: 2px;
    font-size: 4px;
    border-radius: 30%;
  }
  .closeDiv i {
    font-weight: 100;
  }
}

.partner-journey-container {
  text-align: center;
}
.partner-journey-container h4 {
  margin: 0 0 4px;
}

.partner-journey-container--mod{
  margin-top: 60px !important;
}

.partner-journey-container .container-para {
  font-size: 16px;
  line-height: 22px;
}

.partners-subcontainer h3,
.agri-asset-container h3 {
  text-align: center;
  margin-bottom: 40px;
}

.agri-asset-container h3{
    margin-bottom: 0;
}
.partners-subcontainer .contentContainer,
.agri-asset-container .contentContainer {
  text-align: left;
  padding: 0 !important;
}
.agri-asset-container{
    padding-top: 0 ;
}
.partners-subcontainer .MuiGrid-container
 {
  align-items: center;
  margin: 60px 0;
}

/* .partnerSubContainer{
  margin: 0 0;
} */

/* .partnerSubContainer h3{
  text-align: center;
}
.partnerSubContainer .contentContainer{
text-align: left;
padding: 0 !important;
} */
.partners-subcontainer--mod{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.section3Container--mod{
  margin-top: 50px;
}

.bussinessPartnerContainer{
  padding: 60px 0;
}

@media screen and (max-width:769px){
  .partner-journey-container--mod{
    margin-top: 40px  !important;
  }
}


@media screen and (max-width:992px) and (min-width:480px){
  .businessPartnerContainer{
    padding: 120px 0px;
  }
}