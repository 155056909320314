.labelfont
{
    font-size:10px;
}
.alignleft
{
    float:left;
}
.alignright
{
    float : right;
}

#modalwidth
{
    max-width:95% !important;
}
.btn-primary {

    font-weight: 900 !important; 

}


.btn-circle {
    padding: 0.2rem 0.6rem;
}

.dropdownwidth2
{
    width: 22%;
    /* color:black !important; */
    margin-left: -15px;
    border-color:black;
}
.searchbox_color
{
    border-color: black;
    border-radius: 7px;
    outline-color: white;
}


.th_width
{
    width:200px;

}
.redfont
{
    color: red;
}
.blackfont
{
    color:black;
}
.dropdown-content{
    color:black !important
}
.custom-button {
    padding: 5px ;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #3ECF8E;
    color: white;
  }
  
.custom-button:hover {
    color: #f3f7f5;
    background-color: #70d0a5;
  }

  .page-link{
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #3ECF8E;
    color: white;
    margin: 2px;
  }

  .page-link:hover {
    color: #f3f7f5;
    background-color: rgb(83, 133, 198);
  }

  .custom-button-view {
    padding: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #2b4276;
    color: white;
    margin: 2px;
  }
  .custom-button-edit {
    padding: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: rgb(85, 160, 203);
    color: white;
    margin: 2px;
  }


  .custom-button-delete {
    padding: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: rgb(207, 62, 81);
    color: white;
    margin: 2px;
  }
  .pagination {
    padding: 1%;
  }
  .custom-button-cancel {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: rgb(82, 75, 75);
    color: white;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5; /* You can also add opacity to visually indicate it's disabled */
    /* Add other styles as needed */
  }

  button:disabled {
    opacity: 0.6; /* Example: reduce opacity for a visually disabled effect */
    cursor: not-allowed;
    pointer-events: none;
    /* Example: change cursor to not-allowed */
    /* Add other styles as needed */
  }

  .sk-cube-grid {
    width: 40px;
    height: 40px; 
    margin: 100px auto;
  }
  
  .sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #3ECF8E;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }
  
  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  
  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s; 
    animation-delay: 0.3s; 
  }
  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s; 
    animation-delay: 0.4s; 
  }
  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s; 
    animation-delay: 0.1s; 
  }
  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s; 
    animation-delay: 0.2s; 
  }
  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s; 
    animation-delay: 0.3s; 
  }
  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s; 
    animation-delay: 0s; 
  }
  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s; 
    animation-delay: 0.1s; 
  }
  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s; 
    animation-delay: 0.2s; 
  }

  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1); 
      transform: scale3D(1, 1, 1); 
    } 35% {
      -webkit-transform: scale3D(0, 0, 1); 
      transform: scale3D(0, 0, 1); 
    }
  }
  
  @keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1); 
      transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1); 
      transform: scale3D(0, 0, 1); 
    }
  }
  