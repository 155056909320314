.farmerContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-image: url("/assets/img/bgImg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* min-height: 100vh; */
    text-align: center;
    height: auto;
}

.farmerContainer .mainCnt {
    margin-top: 175px;
    background: rgba(33, 33, 33, .6);
    display: inline-block;
    position: relative;
}

.farmerContainer .infoCnt {
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    /*    height: 100vh;*/
}

.farmerContainer .descCnt {
    color: #FFF;
    padding: 35px 55px;
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;
    display: inline-block;
}

.farmerContainer .descCnt:hover, .farmerContainer .iconCnt.closeCnt:hover {
    /*    border: 1px solid #FFF;*/
    background: rgba(33, 33, 33, .4);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.farmerContainer .iconCnt {
    color: #FFF;
    display: inline-block;
    padding: 20px;
    border-radius: 50%;
    border: 1px solid #FFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.farmerContainer .iconCnt i {
    font-size: 55px;
}

.farmerContainer .descCnt p {
    margin: 0;
    margin-bottom: 5px;
}

.farmerContainer .iconCnt.closeCnt {
    position: absolute;
    padding: 5px;
    right: 0px;
    top: 0;
}

.farmerContainer .iconCnt.closeCnt i {
    font-size: 35px;
}
