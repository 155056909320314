.techology-img{
filter: brightness(0) saturate(100%) invert(76%) sepia(27%) saturate(1031%)
hue-rotate(95deg) brightness(91%) contrast(80%);
height: 64px !important;
width: 64px !important;
}

.feauresIcon{
    width: 16px !important;
    height: 16px !important;
    margin-top:6px;
}
.feauresIcon:hover{
    cursor: initial !important;
}
.technologyBtn{
    background-color: #3ECF8E  !important; 
}