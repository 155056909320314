.sectionGrey{
    background-color: #f6f6f6;
}
.sectionWhite{
    background-color: white;
}
.lists li{
    list-style: none;
}
.whyAgristack{
    padding-bottom: 30px;
}

.agristackContainer{
    background-position: center;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.432)),url("../../images/agri3.jpg") !important;
}
.technology-section .contentContainer{
    padding: 82px 0 0 !important;
}

.lists li{
    margin-bottom: 0 !important;
}
/* .technology-section{
    margin-bottom: 40px;
} */
.technology-section .contentContainer ul{
    padding: 0;
}
.agristackContainer img{
    width: auto !important;
    height: auto !important;
}
.technology-subsection{
    margin-bottom: 20px !important;
}

@media screen and (max-width:769px){
    .technology-section .contentContainer{
        padding: 60px 0px 0 !important;
    }
}