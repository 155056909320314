/* Add this CSS in your styles or import it in your component's stylesheet */

.pagination {
    display: flex;
    justify-content: center;
  }
  
  .pagination a {
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .pagination a:hover {
    background-color: #f0f0f0;
  }
  
  .pagination a.selected {
    background-color: #3498db;
    color: #fff;
    border: 1px solid #3498db;
  }
  