.processingServiceBg{
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("../../images/processingService/banner.jpg");
}
.processingSupport1 {
  background-image: url("../../images/processingService/image1.jpg");
}
.processingSupport2 {
  background-image: url("../../images/processingService/image3.jpg");
}
.processingSupport3 {
  background-image: url("../../images/processingService/image2.jpg");
}
.processingSupport4 {
  background-image: url("../../images/processingService/image4.jpg");
}
