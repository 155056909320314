.assetService{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("../../images/installation-bee-hives-new-location.jpg");
}

.moneyMakingSupport1{
    background-image: url("../../images/processingService/farming-capacity.jpg");
}

.moneyMakingSupport2{
    background-image: url("../../images/processingService/storage-capacity.jpg");
}

.farm-registration{
    background-image: url("../../images/green-rice-field-min.jpg");
}

.retail-registration{
    background-image: url("../../images/rice-sacks-factory-min.jpg");
}
.manufacturing-registration{
    background-image: url("../../images/photo-grey-pipes-tanks-chemistry-medicine-production-pharmaceutical-factory-interior-hightech-factory-modern-production-min.jpg");
}
.retailers-img{
    background-image: url("../../images/close-up-female-business-owner-hand-hold-tablet-device-checking-inventory-stock-shop-warehouse-distributor-factory-business-ideas-concept.jpg");
 
}

.manufacturer-img{
    background-image: url("../../images/portrait-smiling-young-warehouse-worker-working-cash-carry-wholesale-store.jpg");
 
}