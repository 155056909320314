/* AddToCart.css */

.add-to-cart-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .cart-items-container {
    margin-top: 20px;
  }
  
  .no-items-message {
    text-align: center;
    margin: 20px 0;
    color: #555;
  }
  
  .cart-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .cart-img img {
    max-width: 100%;
    height: auto;
  }
  
  .cart-details {
    flex-grow: 1;
    margin-left: 15px;
  }
  
  .cart-buttons button {
    padding: 8px 15px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cart-buttons button:hover {
    background-color: #c82333;
  }
  
  .total {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .payment-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .make-payment-btn,
  .add-btn {
    padding: 10px 20px;
  }
  
  .cancel-link {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    color: #007bff;
    transition: color 0.3s;
  }
  
  .cancel-link:hover {
    color: #0056b3;
  }
  /* AddToCart.css */

.add-to-cart-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Ensure the container respects the inner scrollbar */
}

.close-button-container {
  text-align: right;
}

.cart-items-container {
  margin-top: 20px;
  max-height: 250px; /* Set the maximum height for the items container */
  overflow-y: auto; /* Add a vertical scrollbar when content exceeds the height */
}
.close-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-cart-icon {
  color: #555; /* Adjust the color as needed */
  font-size: 24px; /* Adjust the font size as needed */
  transition: color 0.3s ease; /* Optional: Add a smooth color transition */
}

.close-cart-icon:hover {
  color: #f00; /* Adjust the hover color as needed */
  cursor: pointer;
}


  