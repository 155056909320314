.cbo-advisory-img{
background-image: url("../../images/CBO-community/young-indian-agronomist-giving-liquid-fertilizer-bottle-farmer-saying-product.jpg");
}

.cbo-advertisement-img{
    background-image: url("../../images/CBO-community/machinery.jpg");

}
.cbo-buy-inputs-img{
    background-image: url("../../images/CBO-community/holding-two-types-fertilizers.jpg");

}

.cbo-price-discovery-img{
    background-image: url("../../images/CBO-community/close-up-coins-table-min.jpg");

}
.cbo-site-updates-img{
    background-image: url("../../images/CBO-community/site-update.jpg");

}
.cbo-jobs-img{
    background-image: url("../../images/CBO-community/close-up-briefcase-min.jpg");

}

.cbo-club-updates-img{
    background-image: url("../../images/CBO-community/agriculture-gardener-farm-harvest-field.jpg");

}

.cbo-branding-img{
    background-image: url("../../images/CBO-community/fresh-food-healthy-lifestyle-organic-min.jpg");

}

.cbo-project-report-img{
    background-image: url("../../images/CBO-community/financial-charts-with-pen-table-min.jpg");

}

.cbo-pitch-deck-img{
    background-image: url("../../images/CBO-community/group-people-working-table-min.jpg");

}
.cbo-tenders-img{
    background-image: url("../../images/CBO-community/closeup-hand-signing-paper-workspace.jpg");

}

.FFS{
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../images/young-indian-agronomist-banker-showing-tablet-with-farmers-agriculture-field.jpg") !important;
}