.homeSubSection img{
  width: 100%;
  height: 100%;
}

.homeSubSection {
  margin-top: 20px;
  padding: 20px 0;
}

.homeSubSection img:hover {
  cursor: auto !important;
}

.homeSubSection .MuiButton-root{
    text-transform: none !important;
}
.MuiButton-containedPrimary {
  color: #fff !important;
  text-decoration: none;
}

.fontBold{
  font-weight: 600;
}
