/* .solutionEcosystem-container img:hover{
    cursor: initial !important;
} */
.web-img{
    display: block !important;
}
.mob-img{
    display: none !important;
}

@media screen and (max-width:769px) {
    .web-img{
        display: none !important;
    }
    .mob-img{
        display: block !important;
    }
    
}