.service-type-container{
text-align: center;
}
.service-type-container .container-para{
    margin-bottom: 0 ;
}

.service-type-container .modal{
    left: 50% !important;
    border: transparent !important;
    }