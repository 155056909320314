.aboutSubSection img{
width:100%;
height: 100%;
border-radius: 12px;
}
.aboutSubSection{
    margin-top: 20px;
    padding: 40px 0;
}

.aboutSubSection img:hover{
    cursor: auto !important;
}