
#videoSection {
    max-width: 100%;
    text-align: center;
  }
  #imagesSection {
    color: white;
    max-width: 100%;
    text-align: center;
  }
  #postsSection {
    color: white;
    display: inline;
    text-align: center;
  }
  .filterContent{    
    display: inline;
    margin: 5px;
    padding: 10px;      
  }
  .filterContent>input{
    margin-right: 5px;
  }
  .fast-transition {
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 1px 2px rgba(0,0,0,0.15);
      /* transition: all 0.3s ease-in-out; */
    }
    
   /*  .fast-transition::before {
     
     
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 5px;
      box-shadow: 0 5px 15px rgba(0,0,0,0.3);
      transition: opacity 0.3s ease-in-out;
    }
    
    .fast-transition:hover {
      transform: scale(1.2, 1.2);
    }
    .fast-transition:hover::after {
      opacity: 1;
    } */
  
  #locationSection {
    padding-bottom: 50px;
    margin-top: 50px;
  }
  .apiTesting {
    color: whitesmoke;
  }
  .gridVideos {
    display: inline-flex;
  }
  .videosWrapper {
    display: inline;
    width: 100%;
    
  }
  
  #videoFile {
    margin-top: 20px;
    vertical-align: center;
    border-radius: 9px;
    padding: 18px;
    cursor: pointer;
  }
  .uploadBtn {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  
  .serchFilterContainer {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .searchbar {
    float: left;
    margin-top: 25px;
    padding: 13px;
    width: 100%;
    border: 1px solid rgb(17, 19, 19);
    border-radius: 8px;
  }
  .filterBtn {
    float: right;
    margin-top: 30px;
    border-radius: 5px;
    padding: 5px;
  }
  
  
  /* Filter Box */
  .filterBoxContainer {
    margin-bottom: 20px;
  }
  
  .applyBtn{
    margin: 10px 20px;
  }
  .cancelBtn{
    margin: 10px 20px;
  }
  #filterBox{
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  }
  /* homepageNavbar */
  .homepageNavbar {
    margin-top: 20px;
    width: 100%;
  }
  .homepage-navbar {
    list-style: none;
    display: inline-flex;
    width: 100%;
  }
  .homepage-nav-item {
    padding: 10px;
  }
  .navbarLeft {
    float: left;
    padding: 10px;
  }
  /* .homepageBtn {
    background-color: rgb(3 44 38);
    color: whitesmoke;
  }
  .homepageBtn:hover {
    color: rgb(67, 163, 149);
  }
  .homepageBtn:focus {
    outline: rgb(67, 163, 149) !important ;
    border-color: rgb(67, 163, 149) !important ;
  } */
  
  .dropdown-menu li {
    background-color: rgb(201, 238, 232);
  }
  .homepage-dropdown-menu {
    background-color: rgb(67, 163, 149);
    border-color: rgb(67, 163, 149);
    padding: 1px;
  }
  .tenderHeading {
    float: left;
    margin: 20px 0px 20px 10px;
  }
  .tendersListDiv{
    margin-bottom: 30px;
    
  }
  .downloadDocument{
    cursor: pointer;
  }
  /* Pagination */
  .paginationComponent{
    float: right;

  }
  .pagination {
    margin: 15px auto; 
    display: flex;
    list-style: none;
    outline: none;
  }
  /* .pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #47ccde ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #47ccde ;
    border-color: #47ccde;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #47ccde
  } */
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
  #tendersContainer{
    overflow-x: hidden;
  }
 
  .community-cards{
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 8px;
    background-color: #fff;

  }
  .community-cards img:hover{
    cursor: auto !important;
  }
  .bordered-div{
    background-color: #3ecf8e;
    height: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    }

    .community-card-body span{
      color: #3ECF8E;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    }
  
    .scrollable-container{
      height: 210px;
      overflow: auto;
      padding: 0 20px;
      background-color: #fff !important;
    }
    .scrollable-container-mod{
      height: 285px !important;
      padding-bottom: 20px !important;
    }
    .community-card-body h2 {
      font-size: 20px;
      line-height: 28px;
      color: #575757;
      word-wrap: break-word;
      font-weight: 500;
  }
  .disabledBtn{
    pointer-events: none;
    background-color: #d4d3d3 !important;
    border: 1px solid #d4d3d3;
  }
  .community-button-container{
    border-top: 1px solid #3ECF8E;
  }

  .community-card-body h6{
    font-size: 14px;
    /* margin-bottom: 10px; */
    color: #808080;
    line-height: 24px;
  }

  .community-card-body h5{
    font-size: 13px;
    /* margin-bottom: 10px; */
    color: #808080;
    line-height: 24px;
  }

  .community-card-body h5 span{
    font-size: 13px;
    font-weight: normal;
   
  }
.community-card-body p{
  font-size: 14px;
  margin-bottom: 8px;
  color: #808080;
}

.scrollable-container::-webkit-scrollbar {
  width: 10px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #ffffff;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 10px;
  border: 3px solid #ffffff;
}