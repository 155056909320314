ul{
    list-style:none;
  }
  .side-nav-categories {
      padding: 0px;
      margin-top: 0px;
      padding-bottom: 0px;
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: #f5f5f5 #eee #d5d5d5 #eee;
      box-shadow: 0 5px 0 rgba(200,200,200,.2);
      margin-bottom: 30px;
      width: 250px;
      top: 100px;
  }
 

  
  ul#category-tabs {
      list-style: none;
      padding: 0;
      margin: 0;
      padding: 0;
  }
  
  ul#category-tabs li {
      display: block;
      position: relative;
      margin: 0;
      border-bottom: 1px #ececec solid;
      padding: 10px 18px;
  }
  ul.sub-category-tabs li {
      padding: 2px  !important;
  }
  ul.sub-category-tabs li {
      border-bottom: 0px !important;
  }
  
  ul#category-tabs li a {
      /* color: #333; */
      font-weight: 700;
      margin-bottom: 0;
      font-size: 12px;
  }
  
  ul#category-tabs li a i {
      top: 12px;
      right: 18px;
      position: absolute;
      cursor: pointer;
      width: 16px;
      height: 16px;
      padding: 2px;
      color: #ed6663;
  }