.care-service-bg{
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../../images/agriproducer-img/Services/agronomists-doing-quality-control-hemp-field-min.jpg") !important;
}

.advisory-service{
background-image: url("../../../images/agriproducer-img/Services/care/woman-science-assistant-agricultural-officer-greenhouse-farm-research-melon-min.jpg");
}

.veterinary-service{
  background-image: url("../../../images/agriproducer-img/Services/care/veterinary-farm-walking-cowshed-checking-cows.jpg");

}