.abn-container{
    padding: 30px 0 0px 0;
}
.abn-container .MuiButton-root{
    text-transform: none;
}
.networkImg{
    width: 50% !important;
    height: 50% !important;
}
.mob-visible{
    display: none;
    width: auto;
    height: 100%;
    margin: 0 auto;
}
.mob-hiden{
    display: block;
    width: 100%;
    height: 100%;
}

.abn-container img{
    width: 100%;
    height: auto;
}
.abn-container img:hover{
    cursor: initial !important;
}
.abn-container a{
    text-decoration: none;
    line-height: 23px;
    color: white;
}

.abn-container button{
    margin-top: 25px;
}
@media screen and (min-width:769px){
    .abn-container .container-para{
        padding: 0 150px;
    }
.mob-visible{
    display: block;
}
.mob-hiden{
    display: none;
}
}

@media screen and (max-width:769px){
    .abn-container{
        padding: 20px 0 0px 0;
    }
}