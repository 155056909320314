.googleMapContainer{
    width: 100%;
    height: 100vh;
}

@media screen and (max-width: 639px) {
    .googleMapContainer{
        width: 100%;
        height: 50vh;
    }
}
