.stack-banner{background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.65)
      ),
      url("../../images/3d-rendering-geometric-shapes.jpg") !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15% 0 10%;
    }
    .stack-banner h1{
        font-size: 32px;
    }

    .stack-banner a{
        display: block;
        color: #ffffff;
    border: 1px solid #ffffff;
    padding: 10px 30px;
    font-size: 24px;
    text-decoration: none;

    }
@media screen and (min-width:768px){
    .stack-container :first-child{
        width: 35%;
      }
      .stack-container :nth-child(2) {
        width: 45%;
      }
      .stack-container :nth-child(3) {
        width: 55%;
      }
      .stack-container :last-child{
        width: 65%;
      }
    }

    @media screen and (min-width:769px) and (max-width:992px){
     
        .stack-banner{
            padding: 20% 0;
        }
    }

    @media screen and (max-width:769px){
        .stack-banner h1{
            font-size: 28px;
        }
        .stack-banner a{
            width: 80%;
            font-size: 22px;
        }
        .stack-banner{
            padding: 30% 0;
        }
    }

