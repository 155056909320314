.adminTendersDiv h3{
    text-align: center;
    margin-bottom: 30px;
}
.card .container-heading2{
    margin-bottom: 0;
}
.card-body span{
    color: #f5f5f5;
    font-size: 14px;
}