.section1Container {
    width: 100%;
    max-width: calc(100%+0px);
    margin: 0px;
    height: calc(100vh - 64px);
    padding: 0 !important;
    
}

.imageWrapper {
    height: 100vh;
}

.imageCnt {
    z-index: 9;
    color: #FFF;
    background: rgba(33, 33, 33, .4);
    padding: 35px;
}

.imageCnt h1 {
    margin-top: 0;
}

.imageCnt p {
    max-width: 490px;
    font-weight: bold;
    font-size: 14px;
}

.imageCnt button {
    color: #FFF;
    margin-right: 10px;
}
.imageCnt a{
    text-decoration: none;
}

.becomeInvestor_modelcontent{
    position: absolute;
    z-index: 9999;
    /* top: 75px; */
    background: #FFF;
    padding: 25px;
    margin: auto;
    width: 350px;
}
/*
.carousel.carousel-slider {
    max-height: calc(100vh - 65px);
}
*/


/*

.imageWrapper {
    position: relative;
}

.imageWrapper {
    max-height: calc(100vh - 70px);
}

.imageWrapper .imageInfoCnt {
    position: absolute;
    color: #FFF;
    top: 20%;
    left: 75px;
}

.imageInfoCnt h1 {
    margin-bottom: 0px;
}

.imageInfoCnt p {
    max-width: 450px;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}
*/
.storeButtons{
    display: inline-flex;
}
@media screen and (max-width: 639px) {
    .section1Container {
        width: auto;
        height: auto;
       
    }
    .imageCnt {
        z-index: 9;
        color: #FFF;
        background: rgba(33, 33, 33, .4);
        width: 200px;
        height: calc(100%-50px);
    }
    .aws-sld__controls{
        visibility: visible !important;
    }
  }
 
  
  @media screen and (min-width: 640px) {
    .section1Container {
        width: 100%;
        max-width: 100% !important;
        height: calc(100vh - 64px);
        padding: 0 !important;
        padding-top: 64px;
    }
  }
