.thankyou-container{
    padding: 40px;
}

.thankyou-container .container-heading1{
    font-size: 22px;
}
.thankyou-container img{
    height: 100px;
    width: 100px;
}
.thankyou-container img:hover{
cursor: inherit !important;
}

.thankyou-container button{
    width:120px;
}
.disabledOTPLink{
    pointer-events: none;
    color: #a5a5a5 !important;
}