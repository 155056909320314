.about-section .container-title{
    margin-bottom: 30px !important;
}


.about-us-banner{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("../../images//technologyfarming.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 315px 0 215px;
    color: white;
    background-attachment: fixed;
}
.about-main-wrapper{
    margin: 30px 0 70px;
}
.aboutSubSection{
    margin-top: 0px !important;
}
.about-us-banner h2{
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
}
.aboutusContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-image: url("/assets/img/bgImg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    text-align: center;
}
.aboutusContainer .SignUpasContainerLightGray {
    text-align: left !important;
}
.aboutusContainer .mainCnt {
    margin-top: 175px;
    background: rgba(33, 33, 33, .6);
    display: inline-block;
    position: relative;
}

.aboutusContainer .infoCnt {
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    /*    height: 100vh;*/
}

.aboutusContainer .descCnt {
    color: #FFF;
    padding: 35px 55px;
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;
    display: inline-block;
}

.aboutusContainer .descCnt:hover, .growerContainer .iconCnt.closeCnt:hover {
    /*    border: 1px solid #FFF;*/
    background: rgba(33, 33, 33, .4);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.aboutusContainer .iconCnt {
    color: #FFF;
    display: inline-block;
    padding: 20px;
    border-radius: 50%;
    border: 1px solid #FFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.aboutusContainer .iconCnt i {
    font-size: 55px;
}

.aboutusContainer .descCnt p {
    margin: 0;
    margin-bottom: 5px;
}

.aboutusContainer .iconCnt.closeCnt {
    position: absolute;
    padding: 5px;
    right: 0px;
    top: 0;
}

.aboutusContainer .iconCnt.closeCnt i {
    font-size: 35px;
}

.rowReverseAboutStart{
    flex-direction: column-reverse;
}
.rowReverseAbout--mod{
flex-direction: row-reverse;
}
/* .aboutBgGrey{
    background-color: #f6f6f6;
} */
@media screen and (max-width:769px){
    .about-main-wrapper{
        margin: 30px 0 70px;
    }
    .aboutSubSection img{
        border-radius: 0;
    }
    .about-us-banner{
        padding: 150px 0 100px;
    }
    .about-us-banner h2{
        font-size: 24px;
        line-height: 40px;
    }
    .rowReverseAbout--mod,  .rowReverseAbout{
        flex-direction: column-reverse;
    }
    .rowReverseAbout--mod img,  .rowReverseAbout img{
        margin-bottom: 30px;
    }
}
   
.about-main-wrapper ul{
    list-style: circle;
        }
 