

/* Increase the font size of the input field */
.react-datepicker__input-container input {
  font-size: 16px; /* Adjust the font size as needed */
}

/* Optionally, you can adjust other styles for the input field */
.react-datepicker__input-container input {
  padding: 8px; /* Add padding if needed */
  border-radius: 10px; /* Add border-radius if needed */
  /* Add more styles as needed */
}
