
.sitesContainer h2{
    top:50px
}
.notFoundContainer{
    margin-top: 30px;
    margin-left: 30px;
}
.topHeadersWrapper{
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.sitesContainer{
    display:flex;
    flex-wrap:wrap;
    max-width: calc(100%+16px);
    flex-direction: column;
    
}

@media screen and (max-width: 639px) {
   
    .sitesContainer{
        display:flex;
        flex-wrap:wrap;
        overflow-x: scroll;
        flex-direction: column;
        
    }
  }
  
  
  @media screen and (min-width: 640px) {
    
  }