.Iaa-tab-buttons {
  background-color: rgb(255, 255, 255);
  color: #3ecf8e;
  border: none;
  width: 50%;
  padding: 10px 20px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: bold;
}

.Iaa-tab-buttons:parent {
  text-align: center;
}

.Iaa-tabs .active {
  background-color: #3ecf8e;
  color: white;
}
/* .Iaa-tabs:first-child{
    text-align: center;
} */

.Iaa-tabs div + div:first-child {
  display: flex;
  justify-content: center;
}

.Iaa-tabs .container-para + div > div:first-child {
  text-align: center;
  display: inline-block;
  border: 1px solid #3ecf8e;
  width: 20%;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}
.Iaa-tabs .cta-title + div > div:first-child {
  text-align: center;
  display: inline-block;
  border: 1px solid #3ecf8e;
  width: 60%;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.Iaa-producers-tab .container-para + div > div:first-child {
  display: flex;
  width: 40%;
}

@media screen and (max-width: 992px) {
  .Iaa-tabs .container-para + div > div:first-child,
  .Iaa-manufacturer-tabs .container-para + div > div:first-child {
    display: flex;
  }
}

@media screen and (max-width: 769px) {
  .Iaa-tabs .container-para + div > div:first-child,
  .Iaa-manufacturer-tabs .container-para + div > div:first-child {
    width: 80% !important;
  }
  .Iaa-tabs .cta-title + div > div:first-child {
    width: 80%;
  }
  .Iaa-producers-tab-pricing .container-para + div > div:first-child
  {
    display: block;
    width: 100% !important ;
  }


}

@media screen and (max-width: 375px) {
.Iaa-producers-tab-pricing .Iaa-tab-buttons{
  font-size: 13px;
  padding:10px 10px;
}
}

.Iaa-tab-buttons{
  background-color: rgb(255, 255, 255);
  color: #3ECF8E;
  border: none;
  width: 25%;
  padding: 10px 20px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: bold;
}


.Iaa-tab-buttons:parent{
  text-align: center;
}

.Iaa-tabs .active{
  background-color: #3ECF8E;
  color: white;
}
/* .Iaa-tabs:first-child{
  text-align: center;
} */

.Iaa-tabs div + div:first-child{
 display: flex;
 justify-content: center;
}


.Iaa-tabs .container-para + div > div:first-child{
  text-align: center;
  display: inline-block;
  border: 1px solid #3ECF8E;
  width: 50%;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.Iaa-tabs--mod .container-para + div > div:first-child{
  width: 10% !important;
}

.Iaa-tabs--mod .Iaa-tab-buttons{
  width:100% !important;
}

.Iaa-producers-tab .container-para + div > div:first-child{
  width: 40%;
}

.Iaa-producers-tab .Iaa-tab-buttons{
  width: 50%;
}

@media screen and (max-width:769px) {
  .Iaa-tabs .container-para + div > div:first-child{
      width: 80%;
  }
  .Iaa-tab-buttons{
      width: 50%;
  }
}