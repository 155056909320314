.logistic-provider-bg{
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../images/logistic-bg-1.jpg") !important;
}

.transport-user-img{
  background-image: url("../../images/acn-services/transport-user.jpg");
}
.space-operator-img{
  background-image: url("../../images/acn-services/space-operator.jpg");
}
.space-owner-img{
  background-image: url("../../images/acn-services/space-owner.jpg");
}
.driver-img{
  background-image: url("../../images/acn-services/driver.jpg");
}
.logistic-manger-img{
  background-image: url("../../images/acn-services/logistics-providers.jpg");
}