.investmentsContainer .mainCnt {
  background: rgba(33, 33, 33, 0.6);
  display: inline-block;
  position: relative;
}

.investmentsContainer .infoCnt {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /*    height: 100vh;*/
}

.investmentsContainer .descCnt {
  color: #fff;
  padding: 5px 50px;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 25px;
  /* margin-bottom: 25px; */
  cursor: pointer;
  display: inline-block;
}

.investmentsContainer .descCnt:hover,
.investmentsContainer .iconCnt.closeCnt:hover {
  /*    border: 1px solid #FFF;*/
  background: rgba(33, 33, 33, 0.4);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.investmentsContainer .iconCnt {
  color: #fff;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.investmentsContainer .iconCnt i {
  font-size: 55px;
}

.investmentsContainer .descCnt p {
  margin: 0;
  margin-bottom: 5px;
}

.investmentsContainer .iconCnt.closeCnt {
  position: absolute;
  padding: 5px;
  right: 0px;
  top: 0;
}

.investmentsContainer .iconCnt.closeCnt i {
  font-size: 35px;
}
.gridItem {
  background-color: #fff;
  margin: 10px 0;
  height: auto;
  min-height: 250px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  transition: width 2s, height 2s, transform 1s;
}

/* .gridItem-body{
    height: 33.3%;
    padding: 10px;
}
.gridItem-title{
    
    display: inline;
    align-items: center;

}
.gridItem-footer{
    height: 33.3%;
    
}
.gridItem-footer img{
    height: 100%;
}
.gridItemContainer{
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}
.gridItemContainer2{
    display: inline-flex;
    justify-content: center;
    margin: auto;
   
} */
.gridItem:hover {
  transition: width 2s, height 2s, transform 1s;
  transform: scale(1.05);
}
.InvestmentContainer {
  text-align: center;
  padding: 30px 0px;
  min-height: 350px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}
.investment-cards {
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  text-align: center;
  flex-direction: column;
}
.activeImg img{
  filter: brightness(0) saturate(100%) invert(67%) sepia(79%) saturate(365%) hue-rotate(98deg) brightness(91%) contrast(85%);
}

.investment-cards .container-heading2{
  font-size: 14px;
  line-height: 20px;
}
.investment-cards img {
  height: 80px;
  width: 80px;
}
.disabledCard {
  pointer-events: none;
}
.investment-subcontainer{
  margin-top: 70px;
  margin-bottom: 60px;
}
@media screen and (max-width: 639px) {
  .gridItem {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .gridItemContainer {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .gridItem-title {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .investment-cards{
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .investorForm .MuiFormControl-marginNormal{
margin: 8px 0 !important;
  }
}
.section3BodyContainer--mod {
  display: flex !important;
}
.disabledCard .investment-cards{
  background-color: #d6d4d47c;
}

@media screen and (min-width: 769px) {
  .section3BodyContainer--mod .section3Body {
    max-width: 100% !important;
    /* width: 200px !important; */
  }
}

@media screen and (max-width: 769px) {
  .section3BodyContainer--mod {
    /* align-items: center !important; */
    justify-content: left !important;
  }
  .InvestmentROIContainer{
    padding-top: 20px !important;
  }
}

.investmentContainer-wrapper {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("../../images/growing-coins-hand-finance-investment-concept-min.jpg") !important;
    padding: 160px 0 120px  !important;
    justify-content: left !important;
    display: block;
    text-align: left !important;
}

.investment-card-wrapper{
    padding-bottom: 50px;
}
.investmentContainer-wrapper h1 {
  font-size: 42px;
  line-height: 38px;
}

@media screen and (max-width: 769px) {
  .investmentContainer-wrapper h1 {
    font-size: 26px;
    line-height: 38px;
  }
  .investment-subcontainer{
    margin-top: 50px;
    margin-bottom: 40px;
  }
}
