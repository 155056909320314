.business-cards{
    border: 1px solid transparent;
    padding: 40px 15px;
    
    height: 100%;
    color:white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.business-cards a {
    border: 1px solid #fff;
    padding: 8px 30px;
    background-color: initial;
    border-radius: 26px;
    /* font-weight: 600; */
}
.business-cards p{
    font-size: 12px;
}

.business-cards h1{
    font-size: 22px;
}

.business-cards h2{
    font-size: 18px;
}

.gbr-farming-bg{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .67), rgba(0, 0, 0, .67)), url("../../images/monetizing-banner.jpg");
}
.gbr-network-bg{

background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0.61)),url("../../images/gbrPN/PNBanner.webp");
}