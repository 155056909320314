
#videoSection {
  max-width: 100%;
  text-align: center;
}
#imagesSection {
  color: white;
  max-width: 100%;
  text-align: center;
}
#postsSection {
  color: white;
  display: inline;
  text-align: center;
}

.filterContent{    
  display: inline;
  margin: 5px;
  padding: 10px;      
}
.filterContent>input{
  margin-right: 5px;
}
.fast-transition {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    /* transition: all 0.3s ease-in-out; */
  }
  
 /*  .fast-transition::before {
   
   
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
  }
  
  .fast-transition:hover {
    transform: scale(1.2, 1.2);
  }
  .fast-transition:hover::after {
    opacity: 1;
  } */

#locationSection {
  padding-bottom: 50px;
  margin-top: 50px;
}
.apiTesting {
  color: whitesmoke;
}
.gridVideos {
  display: inline-flex;
}
.videosWrapper {
  width: 30% !important;
  margin: 1%;
  display: inline-flex;
  float: left;
}

#videoFile {
  margin-top: 20px;
  vertical-align: center;
  border-radius: 9px;
  padding: 18px;
  cursor: pointer;
}
.uploadBtn {
  margin-right: 10px;
  margin-bottom: 10px;
}


.serchFilterContainer {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}
.searchbar {
  float: left;
  margin-top: 25px;
  padding: 13px;
  width: 100%;
  border: 1px solid rgb(17, 19, 19);
  border-radius: 8px;
}
.filterBtn {
  float: right;
  margin-top: 30px;
  border-radius: 5px;
  padding: 5px;
}


/* Filter Box */
.filterBoxContainer {
  margin-bottom: 20px;
}

.applyBtn{
  margin: 10px 20px;
}
.cancelBtn{
  margin: 10px 20px;
}
#filterBox{
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
/* homepageNavbar */
.homepageNavbar {
  width: 100% !important;
  margin-top: 30px !important;
  padding-left: 0px !important;
}
.homepage-navbar {
  list-style: none;
  display: inline-flex;
  width: 100%;
}
.homepage-nav-item {
  padding: 0px !important;
}
.navbarLeft {
  float: left;
  
}

.homepageBtn {
  width: 200px;
  min-height: 30px;
  height: auto;
  /* background-color: rgb(3 44 38); */
  color: whitesmoke;
}
/* .homepageBtn:hover {
  color: rgb(67, 163, 149);
}
.homepageBtn:focus {
  outline: rgb(67, 163, 149) !important ;
  border-color: rgb(67, 163, 149) !important ;
} */
.dropdown{
  position: relative;
  display: inline-block;
}
.dropdown-menu{
  margin-top: 10px;
  display: none;
  position: absolute;
  max-height:250px;
  width: 250px;
  padding-left: 5px;
  overflow:scroll; 
  border: none;
}
.dropdown-menu li {
  background-color: #aaadab !important;
  border: 1px solid #f5f5f5;
}
.homepage-dropdown-menu {
  background-color: rgb(67, 163, 149);
  border-color: rgb(67, 163, 149);
  padding: 1px;
}
.tenderHeading {
  float: left;
  margin: 10px 0px 10px 15px;
  width: 100%;
  display: inline;
  text-align: left;
}
.tendersListDiv{
  margin-bottom: 20px !important;
  padding-top: 0px !important;
  width: 100% !important;
}
.downloadDocument{
  cursor: pointer;
}
/* Pagination */
.paginationComponent{
  width: 100%;
  display: inline-flex;
  float: right;
}
.pagination {
  margin: 15px auto;
  list-style: none;
  outline: none;
  font-family: sans-serif;
  font-weight: 600;
  color: #050c4d !important;
}
/* .pagination > .active > a{
  background-color: #34a4eb !important;
  border-color: #34a4eb !important;
  color: #fff !important;
}
.pagination > li > a{
  border: 1px solid #34a4eb !important;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #34a4eb !important;
  border-color: #34a4eb !important;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #34a4eb !important;
} */
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}
#tendersContainer{
  overflow-x: hidden;
}

@media only screen and (min-device-width: 320px) and (max-device-width:568px){
  .homepageNavbar {
      margin-top: 50px !important;
      width: 100% !important;
      padding-left: 0px;
  }
  .homepage-navbar {
    list-style: none;
    display: inline-flex;
    width: 100%;
  }
  .homepage-nav-item {
    padding: 0px !important;
  }
 
  .tenderHeading {
    float: left;
    margin: 20px 0px 20px 0px;
    width: 100%;
    display: inline;
    text-align: left;
  }
  .videosWrapper {
    margin: 0px;
    display: inline;
    width: 100%;
    float: none;
  }
  
  .tendersListDiv{
    margin-bottom: 30px;
    padding-top: 10px;
    width: 100%;
  }
  .dropdown{
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .dropdown-menu{
    position: sticky;
    max-height:300px;
    width: 100%;
    display: inline-block;
    overflow:scroll; 
  }
  .dropdown-menu li {
    background-color: #aaadab;
    border: 1px solid #f5f5f5;
  }
  /* .dropdown-menu li a{
    color: #ffffff;
  } */
  #dropdownCategories{
    margin-top: 0px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width:1024px){
.homepageNavbar {
    margin-top: 50px !important;
    width: 100%;
    padding-left: 70px;
}
.homepage-navbar {
  list-style: none;
  display: inline-flex;
  width: 100%;
}
.homepage-nav-item {
  padding: 0px !important;
}
.tenderHeading {
  float: left;
  margin: 20px 0px 20px 0px;
  width: 100%;
  display: inline;
  text-align: left;
}
.videosWrapper {
  margin: 0px;
  /* display: inline; */
  width: 100%;
  float: none;
}
.tendersListDiv{
  margin-bottom: 30px;
  padding-top: 20px;
  width: 100%;
}
}


@media screen and (max-width:992px) {
  .videosWrapper{
    width:48% !important;
    margin-bottom: 20px;
  }
}

@media screen and (max-width:769px) {
  .videosWrapper{
    width:100% !important;
  }
}