.section3Body img {
  height: 45px;
  width: 45px;
}

.section3Body{
   transition:  width 2s, height 2s, transform 1s;
}
.section3Body img:hover {
  cursor: default !important;
}

