.updateProfileContainer{
    min-height: 300px;
    background-color: #FAFAFA;
    box-shadow: 2px 5px 25px #888888;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
}