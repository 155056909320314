.Iaa-container h2 {
  font-size: 20px;
  font-weight: 800;
  color: #2a4355;
  line-height: 28px;
}
/* .Iaa-container--mod{
    max-width: 70% !important;
} */
.Iaa-container {
  text-align: center;
  max-width: 70%;
  margin: 10px auto;
  /* border: 0.8px solid #2A4355; */
  padding: 40px 20px;
  border-radius: 17px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 17%), 0 6px 20px 0 rgb(0 0 0 / 13%);
}
.Iaa-Cards {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  padding: 30px;
  border-radius: 17px;
  border: 1px solid transparent;
}
.card-deactivated {
  background-color: #d6d4d47c;
}
.Iaa-Cards img {
  width: auto !important ;
  height: auto !important;
}

@media screen and (max-width: 767px) {
  .Iaa-container h2 {
    font-size: 18px;
  }
  .Iaa-container {
    min-width: 70%;
    padding: 40px 20px 30px;
  }
}

.imageHover:hover {
  cursor: pointer;
  /* border: 1px solid #3ecf8e69; */
  box-shadow: none;
  box-shadow: 0 2px 5px 0 #3ecf8e69, 0 2px 10px 0 #3ecf8e69;
}

.imgCursor img:hover {
  cursor: pointer !important;
}

.Iaa-Cards img {
  height: 64px !important;
  width: 64px !important;
}
.filteredimg {
  filter: brightness(0) saturate(100%) invert(67%) sepia(79%) saturate(365%)
    hue-rotate(98deg) brightness(91%) contrast(85%);

  /* filter: brightness(0) saturate(100%) invert(23%) sepia(8%) saturate(2702%) hue-rotate(156deg) brightness(100%) contrast(86%); */
}
