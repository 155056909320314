.customLoaderLinear{
    height: 7px !important;
}
.shadowBox{
    min-width: 100px;
    border-radius: 10px;
    min-height: 50px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    text-align: center;
}