.network-banner{
    /* background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("../../../images/CBN2.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    display: flex;
    padding: 300px 0 220px;
    background-attachment: fixed;
}

.network-banner h1{
    font-size: 30px;
    line-height: 45px;
}

@media screen and (max-width:769px){
    .network-banner{
        padding: 150px 0 100px;
    }
    .network-banner h2{
        font-size: 24px;
        line-height: 40px;
    }
}