/* .wether {
    min-height: 100vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.75));
    padding: 25px;
  }
  
  
  .location-box .location {
    color: #FFF;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
  }
  
  .location-box .date {
    color: #FFF;
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    text-shadow: 2px 2px rgba(50, 50, 70, 0.5);
  }
  
  .weather-box {
    text-align: center;
  }
  
  .weather-box .temp {
    position: relative;
    display: inline-block;
    margin: 30px auto;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
  
    padding: 15px 25px;
  
    color: #FFF;
    font-size: 102px;
    font-weight: 900;
  
    text-shadow: 3px 6px rgba(50, 50, 70, 0.5);
    text-align: center;
    box-shadow: 3px 6px rgba(0, 0, 0, 0.2);
  }
  
  .weather-box .weather {
    color: #FFF;
    font-size: 48px;
    font-weight: 700;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
  } */
.wether {
  height: 400px;
  overflow-y: scroll;
}
.weatherIcons {
  width: 40px;
  height: 20px;
}
.weatherIcons:hover {
  cursor: initial !important;
}

.weatherConditions {
  height: 100% !important;
  width: auto !important;
}
.weather-box--mod {
  box-shadow: 1.2px 1.2px 8px rgb(0 0 0 / 20%);
  width: 33.3%;
  padding: 5px;
}
.weather-box-wrapper {
  flex-wrap: wrap;
}


@media screen and (max-width:769px){
  .wether {
    height: 300px;
  }
  .weather-box--mod {
    /* box-shadow: 1.2px 1.2px 8px rgb(0 0 0 / 20%); */
    width: 100%;
  }
}

.weatherIcons {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);}