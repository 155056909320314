.AgriMinorContainer{
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
    width: 100%;
    color: white;
    background-repeat: no-repeat;
    padding: 300px 0 200px;
background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url("../../images/gbr-network-agriminer.jpg");
}

.gridItem{
    padding:20px 10px;
}
.agriminor-journey-container {
    margin-top: 60px;
    margin-bottom: 37px;
text-align: center;
}
.agriMinerContainer .contentContainer{
    text-align: left;
    padding: 0 25px 50px!important;
} 
/* .AgriMinorContainer {
    text-align: center;
} */

.AgriMinorContainer h3{
    font-size: 36px;
    line-height: 38px;
}

.AgriMinorContainer p{
    font-size: 18px;
    line-height: 26px;
    max-width: 100% !important;
    margin: 0 0 26px;
}
.agriminer-wrapper{
    margin: 70px 0 50px;
}
/* .benefit-title{
    margin-bottom: 42px;
} */
.who-are-agriminer-container{
    padding-bottom: 52px;
}
@media screen and (min-width:1024px){
.benefit-container .col-lg-2{
    width: 20% !important;
}
}
.AgriMinorContainer .imageCnt, .AgriMinorContainer  button{
margin-bottom: 20px;
margin-right: 0;
}

.section3BodyContainer .container-para{
    line-height: 24px;
}
@media screen and (min-width:1024px){
 .agriminor-journey-container .section3Body{
    width: 20%;
    padding: 20px 10px;
 }
}
@media screen and (max-width:1024px){

    .agriminor-journey-container .section3BodyContainer{
    justify-content: center;
}
}

@media screen and (max-width:992px){
    .rowReverse{
        flex-direction: column-reverse;
    }

    .rowReverse img{
margin-bottom: 30px;
    }
    .rowReverse--mod{
        margin-bottom: 30px;
    }
}
@media screen and (max-width:769px){
    .who-are-agriminer-container {
        padding-bottom: 42px;
    }
    .AgriMinorContainer{
        background-position: center;
        padding: 150px 0px 100px;
    }
    .agriminer-wrapper{
        margin: 50px 0 30px;
    }
    .benefit-title{
        margin-bottom: 22px;
    }
    .agriminor-journey-container{
        margin-top: 24px;
    }
   .AgriMinorContainer .imageCnt{
    width: auto !important;
    padding: 120px 10px 100px;
}

.AgriMinorContainer h3{
    font-size: 24px;
    line-height: 36px;
}

.AgriMinorContainer p{
    font-size: 16px;
    line-height: 26px;
}
}


/* .dropdown{
    width: 20% !important;
} */