.IAABanner {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/ET2.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 260px 0 210px;
}

@media screen and (min-width: 992px) {
  .IAABanner .gridSection {
    width: 690px;
  }
}

.IAAContainer {
  padding: 20px 0 50px !important;
}

.IAAContainer .contentContainer {
  padding: 82px 0 0 !important;
}

@media screen and (max-width: 769px) {
  .IAAContainer .contentContainer {
    padding: 60px 0px 0 !important;
  }
}
.IAABanner h1 {
  font-size: 36px;
  line-height: 38px;
}

.IAAContainer .contentContainer {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
@media screen and (max-width: 769px) {
  .IAAContainer {
    padding: 0px 0 30px !important;
  }
  .IAABanner {
    padding: 180px 0 120px;
  }
  .IAABanner h1 {
    font-size: 26px;
    line-height: 38px;
  }
}
