.signinPageContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    text-align: center;
}

.signinPageContainer.default {
    background-image: url("/assets/img/bgImg.jpg");
}

.signinPageContainer.investment {
    background-image: url("/assets/img/investmentBg.png");
}


.signinPageContainer.govenmentAgency {
    background-image: url("../../../images/govenmentAgency1.jpg") ;
}

.signinPageContainer.veterinary {
    background-image: url("/assets/img/Veterinary.jpg") !important;
}

.signinPageContainer.grower {
    background-image: url("/assets/img/fpo.jpg");
}

.signinPageContainer.farmer {
    background-image: url("/assets/img/grower.jpg");
}

.signinPageContainer.buyer {
    background-image: url("/assets/img/buyer.jpg");
}

.signinPageContainer.reseller {
    background-image: url("/assets/img/seller.jpg");
}

.signinPageContainer.agent {
    background-image: url("/assets/img/agent.jpg");
}

.signinPageContainer.merchant {
    background-image: url("/assets/img/seller_2.jpg");
}

.signinPageContainer.offtaker {
    background-image: url("/assets/img/offtaker.jpg");
}

.signinPageContainer.technologyprovider {
    background-image: url("/assets/img/techprovider.png");
}

.signinPageContainer.insuranceprovider {
    background-image: url("../../../images/insuranceproviders.jpg");
}

.signinPageContainer.logisticsprovider {
    background-image: url("/assets/img/logisticsprovider.jpg");
}


.loginOuterContainer {
    margin: 90px auto 0 !important;
    
}

.forgotPwdContainer{
    min-height: 300px;
    background-color: #FAFAFA;
    box-shadow: 2px 5px 25px #888888;
    border-radius: 5px;
    text-align: center;
    padding: 25px 45px 10px 45px;
    max-width: 350px !important;
}

.loginFields {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}


.loginBtn {
    margin-top: 5px !important;
    color: #FFF !important;
}

.rememberMe {
    float: left;
    text-align: left;
    font-size: 12px !important;
    color: #969798 !important;
    margin-top: 15px;
    letter-spacing: .5px;
}

.rememberMe span:nth-child(1) {
    color: #68696D !important;
}

.rememberMe input[type="checkbox"]:checked {
    color: #F3BD48 !important;
}

.rememberMe span input[type=checkbox] {
    border: 2px solid #68696D !important;
    background-color: #68696D;
}

.rememberMe span:nth-child(2) {
    font-size: 12px !important;
    color: #68696D !important;
}

.forgotPwdLink {
    font-size: 12px;
    color: #CBCDCD;
    margin-top: 12px !important;
    /*margin-bottom: 10px !important;*/
    letter-spacing: .5px;
    cursor: pointer;
}

.forgotPwdLink:hover {
    color: #68696D;
}