.card-body-wrapper p,
.card-body-wrapper h6 {
    color: #808080;
    font-size: 14px;
}
.gbr-engage-container .dropdown-menu{
    margin-top: 5px;
    border:0.8px solid #c0bdbd !important;
box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
/* box-shadow: 10px 0 5px -2px #888; */

    /* box-shadow: 0px 15px 10px -15px #111;     */

/* max-width: 300px !important; */
overflow: auto;
background-color: #fff;
/* padding: 0 !important; */
/* --bs-dropdown-item-padding-x: 0 !important; */
padding-left: 0 !important;
}

.gbr-engage-container .dropdown-menu li {
    background-color: transparent !important;
}


.gbr-engage-container .dropdown-menu::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  
  .gbr-engage-container .dropdown-menu::-webkit-scrollbar-track {
    background: #ffffff;
  }
  
  .gbr-engage-container .dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
