.benefitsCardContainer{
    border-radius: 4px;
    padding: 30px;
}
.benefitsCardContainer:hover{
  box-shadow:0.7143rem 0.7143rem 2.1429rem 0 rgba(0,0,0,0.04);
}
.benefitsCardContainer img{
    height: 60px;
    width: 60px;
}
.benefitsCardContainer h3{
    font-size: 16px;
    line-height: 26px;
    color: #121212bf;
    font-weight: 700;
}

.benefitsCardContainer p{
    font-size: 16px;
    line-height: 24px;
    color: #323131bf;
}


@media screen and (max-width:769px){
    .benefitsCardContainer{
        box-shadow: 0.7143rem 0.7143rem 2.1429rem 0 rgba(0,0,0,0.04);
        padding: 20px;
        width: 100%;
    }
}