.cta-container{
    background-color: #ededed !important;
    color: #242424;
    border-radius: 10px;
    padding: 50px;
}
.cta-container h2{
    color: #666666 !important;
    /* margin-bottom: 30px !important;
    font-size: 30px !important; */

}

.cta-container a,
.cta-container button{
    padding: 10px 30px;
    border: 2px solid #666666;
    color: #666666;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    text-decoration: none;
}

@media screen and (max-width:360px){
    .cta-container{
     padding: 20px ;
    }
}