.locTag {
  color: #3f51b5;
  font-weight: bold;
  margin: 12px;
  font-family: emoji;
  font-size: 20px;
}

.locBtn {
  background-color: #3ecf8e;
  width: 50px;
  height: 36px;
  margin: 10px;
  border-radius: 5px;
  width: 70px;
  border: none;
  color: black;
  font-weight: 500;
  cursor: pointer;
}
.locBtn:hover {
  background-color: green;
  color: aliceblue;
  transition: ease-in 0.3s;
}

.inp {
  height: 30px;
  border-radius: 7px;
  border: 1.5px solid lightgray;
  opacity: 0.5;
}

.canBtn {
  width: 50px;
  height: 36px;
  margin: 10px;
  border-radius: 5px;
  width: 70px;
  border: none;
  color: white;
  font-weight: 500;
  background-color: rgb(179, 66, 66);
  cursor: pointer;
}

.canBtn:hover {
  background-color: rgb(190, 49, 49);
  transition: ease-in 0.3s;
}

.loadBtn {
  font-size: 13px;
  background-color: #3f51b5;
  color: white;
  border-radius: 5px;
  border-style: none;
  width: 100%;
  height: 30px;
  cursor: pointer;
}



.delBtn {
  width: 50px;
  height: 36px;
  margin: 10px;
  border-radius: 5px;
  width: 70px;
  border: none;
  color: white;
  font-weight: 500;
  background-color: grey;
  cursor: pointer;
}

.delBtn:hover {
  background-color: rgb(34, 34, 184);
  transition: ease-in 0.3s;
}

.delMenuItem {
  color: black !important;
}

.delMenuItem:hover {
  background-color: red !important;
  transition: ease-in 0.3s !important;
  color: whitesmoke !important;
}

.modifydetailsbtn {
  color: blue !important;
}

.modifydetailsbtn:hover {
  background-color: #3f51b5 !important;
  transition: ease-in 0.3s !important;
  color: aliceblue !important;
}

.cnlLoc {
  background-color: #3ecf8e;
  width: 50px;
  height: 36px;
  margin: 10px;
  border-radius: 5px;
  width: 70px;
  border: none;
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.errorMessage {
  padding: 6px 16px;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  background-color: rgb(253, 236, 234);
  color: rgb(97, 26, 21);
 
}
