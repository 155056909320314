.pricing-banner{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("../../images/PricingBanner2.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 350px 0 270px;
    color: white;
    background-attachment: fixed;
    /* height: 100vh; */
}

@media screen and (max-width: 769px){
.pricing-banner {
    padding: 150px 0 100px;
}
}