.asset-market-bg{
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../../../images/agriproducer-img/Services/front-view-jar-with-dirt-stacked-coins-with-plant-min.jpg") !important;
}
.buy-sell-service{
background-image: url("../../../images/agriproducer-img/Services/asset/two-metal-dices-pile-coins-background-stock-chart-edges-dices-are-words-buy-sell-min.jpg");
}

.store-service{
  background-image: url("../../../images/agriproducer-img/Services/asset/storehouse-interior.jpg");

}
.price-discovery-service{
  background-image: url("../../../images/agriproducer-img/Services/asset/close-up-coins-table.jpg");

}
.sell-service{
  background-image: url("../../../images/agriproducer-img/Services/asset/different-types-grains-beans-groceries-bags-market-min.jpg");

}