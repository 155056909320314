.pn-operator{
    background-image: url("../../../images/PNTeam/operator.jpg");
  }
  .pn-owner{
    background-image: url("../../../images/PNTeam/owner.jpg");
  }
  
  .pn-manager{
    background-image: url("../../../images/PNTeam/manager.jpg");
  }
  .pn-qa{
    background-image: url("../../../images/PNTeam/QA.jpg");
  }
  .pn-executive{
    background-image: url("../../../images/PNTeam/executive.jpg");
  }
  .pn-technician{
    background-image: url("../../../images/PNTeam/technician.jpg");

  }