.stepperContainer{
    border-radius: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    margin: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    background-color: white;
}
.MuiStepper-root {
    display: flex;
    padding: 2px !important;
}