.details-header {
  background-color: #3ecf8e;
}
.details-title {
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
}
.details-body p {
  color: #808080;
}

.details-body {
  max-height: 300px;
  overflow-y: auto;
}

.details-body::-webkit-scrollbar {
  width: 10px;
}

.details-body::-webkit-scrollbar-track {
  background: #ffffff;
}

.details-body::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
