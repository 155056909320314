.pricing-card{
    border:1px solid #dfdfdf;
    border-top:8px solid #3ECF8E;
    border-radius: 6px;
    padding: 30px;
    background-color: #3ecf8e17;
    color: #666666;
}

.pricing-card .pricing-card-title{
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 5px;
}

.pricing-card-subtitle{
    line-height: 22px;
    margin: 0;
    margin-bottom: 15px;
    font-size: 16px;
}

.pricing-card-price{
font-size: 38px;
font-weight: 700;
}

.pricing-card-price span{
    font-size: 16px;
    font-weight: 500;
}
.pricing-card .btn{
    background-color: #3ECF8E;
    color: #666666;
}

.pricing-card .btn a{
    text-decoration: none;
    color: #4d4d4d !important;
} 

.pricing-card ul li{
    line-height: 18px;
    margin: 10px 0;
    font-size: 14px;
}

.pricing-card ul li img{
    width: 14px;
    height: 14px;
    margin-top: 4px;
}
.pricing-card ul li img:hover{
    cursor:initial !important;
}