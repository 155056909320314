.loginimage
{
    width:70% ;
    margin-top:20%;
}
.loginbg
{
    background-color: white !important;
}
#errorstyle
{
  color:red;
}
.margin-top-25{
    margin-top: -25px;
}
.usericonsize
{
    font-size: 30px;
    color:black;
}
.rightalign
{
    float: right;
}
.widthsize
{
    width:50%;
    float:right;
}
.alertclass
{
    color:red;
}
/*.icon-bell
{
    color:darkblue;
    font-size: 20px;
}
.infocardmin-height
{
    min-height :390px !important ;
}
.rounded
{
    border-radius: 50% !important;
}
.iconwidth
{
    width:12px;
}
.app-header{
    background-color: #20a8d8;
    padding-left: 10px;
}
.LabeHeaderName{
    color: darkblue !important;
    font-weight: 500;
    font-family: Verdana;
    
}
#submitset
{
    margin-right: 10px;
}*/

.btn-info{
    color: #fff !important;
}

.m-r-15{
margin-left: 10px;
}

.m-r--15{
    margin-left: -15px;
}

.paddingdate{
    padding: 5px;
}

.bottomdiv{
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.tableadd{
    float: right;
    margin-right: 5px;
}

/* .multigauge:hover .hide-button {
    display: block;
    cursor: pointer;
}

.hide-button {
    float: right;
    color: red;
    display: none;
} */