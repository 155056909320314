.for-farmer-section2 img{
width: 100%;
height: auto;

}
.for-farmer-section2 a{
    text-decoration: none !important;
}
@media screen and (min-width:992px){
.for-farmer-section2{
    padding-left: 15%;
    padding-right: 15%;
}
}


@media screen and (min-width:992px){
    .image-container{
        overflow: hidden;
        width: 100%;
        height: 237px;
        overflow: hidden;
    }
    
    .image-container img {
        height: 237px;
        width: 100%;
        transition: 0.5s all ease-in-out;
        object-fit: cover;
    }
    
    .image-container:hover img {
        transform: scale(1.2);
    }
}