.ExploreBannerContainer{
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/1 (1).png") !important;
}


/* @media screen and (min-width:993px){
    #ExploreBannerContainer .gridSection{
        width:70% 
    }
} */
.offcanvas.show:not(.hiding), .offcanvas.showing {
    z-index: 9999 !important;
}

.explore-products-ncards p span a{
    color: inherit !important;
}

    
