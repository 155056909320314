.footerContainer {
  padding: 30px 0;
  border-top: 1px solid #bdbdbd;
  margin: 0;
}

.footerRow {
  margin-top: 20px !important;
}

.footerCol {
  padding-left: 0px !important;
}

.footerCol h5 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #666666;
  font-size: 14px;
}

.footerCol ul {
  text-decoration: none;
  list-style: none;
  padding-left: 2px;
  /* color: #808080; */
  margin-top: 5px;
  margin-bottom: 0px;
}
.footerCol ul > li > a {
  color: #666666 ;
  text-decoration: none !important;
}
.footerCol ul > li > a:hover, .footerCol ul > li > a.active{
  text-decoration: underline !important;
}
.footerCol li {
  font-size: 13px;
  text-align: left;
  cursor: pointer;
  margin-bottom: 4px;
}

.footerCol li:hover {
  text-decoration: underline;
}

.contactFormCnt h5 {
  margin-bottom: 5px !important;
}

.contactFormCnt p {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
}
.footerContainer--mod{
  padding-top: 20px;
  padding-bottom: 20px;
}
.footerContainer--mod span{
  font-size: 14px;
  color: #737373;
}
.gdpInfoText {
  font-size: 13px;
  line-height: 20px;
  margin-top: 5px;
  color: #808080;
}
.gdpInfoText span {
  margin-top: 10px;
  display: block;
}

.contactUsBtn i {
  margin-left: 3px;
  font-size: 16px;
}

.contactUsBtn {
  margin-left: 3px !important;
  color: #fff !important;
  padding: 15px 15px !important;
}

.socialCnt {
  margin-top: 21px;
}

.socialCnt svg {
  width: 22px;
  height: 22px;
  margin-right: 15px;
  cursor: pointer;
}

.tendersLink {
  text-decoration: none;
  color: #bdbdbd;
}
.MuiGrid-spacing-xs-2 {
  width: calc(100% + 0px) !important;
  margin: 0px !important;
}
.gdpInfoText a {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 639px) {
  .footerContainer {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
}

@media screen and (min-width: 640px) {
  .footerContainer {
    padding-left: 43px !important;
    padding-right: 43px !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .socialCnt svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 769px) {
  .footerCol h5 {
    margin: 20px 0;
  }
  /* .footerContainer {
    padding: 10px 0;
  } */
  .footerRow {
    margin-top: 0 !important;
  }
  .socialCnt svg {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }
}

.disabled-link a{
  color: #a3a3a3 !important;
}

.disabled-link a:hover{
  text-decoration: none !important;
}


.disabled-link 
{pointer-events: none;
color: #a3a3a3 !important;}