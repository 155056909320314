.gridItem--mod{
    min-height: auto !important;
    /* margin: 0px !important; */
    min-width: auto !important;

}
.gridItem--mod img{
    width: 45px !important;
    height: 45px !important;
}
.gridItem--mod img:hover{
  cursor: default !important;
}
.gridItem--mod .container-heading2{
  line-height: 26px;
  font-size: 17px;
}

.gridItem--mod .container-para{
  font-size: 16px;
}