.tab-wrapper-container {
  padding: 80px 0;
}
.tab-buttons {
  background-color: transparent;
  width: 25%;
  border: 0px solid transparent;
  border-bottom: 1px solid #808080 !important;
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #808080;
  font-family: open-sans-Regular;
}

.tab-wrapper-container .active {
  border-bottom-color: transparent !important;
  border-right: 1px solid #808080 !important;
  border-left: 1px solid #808080 !important;
  border-top-width: 6px;
  border-top-color: #3ecf8e;
}
.tab-content-container {
  padding: 40px 10px;
}

.Iaa-producers-tab-pricing .container-para + div > div:first-child {
  width: 40%;
  margin-bottom: 40px;
}

.Iaa-producers-tab-pricing .Iaa-tab-buttons {
  width: 50%;
}
@media screen and (min-width: 769px) {
  .tab-content-container {
    background-color: #e2f8eec9;
  }
  .tab-wrapper-container .active {
    background-color: #e2f8eec9;
  }
}
@media screen and (max-width: 769px) {
  .tab-wrapper-container h1 + div {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .Iaa-producers-tab-pricing .Iaa-tab-buttons {
    width: 50%;
  }
  .tab-wrapper-container h1 + div > div:first-child {
    /* display: flex !important; */
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
  }

  .tab-buttons {
    width: 80%;
  }
}
