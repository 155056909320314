#GBRBannerContainer{
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../../images/crowd-tea-picker-picking-tea-leaf-plantation-min.jpg") !important;
}

@media screen and (min-width:992px){
    #GBRBannerContainer{
    padding-top: 190px;
    }
}
@media screen and (min-width:992px){
    #GBRBannerContainer .gridSection{
        max-width: 900px;
    }
    #GBRBannerContainer .MuiGrid-grid-md-2 {
        flex-grow: 0;
        max-width: 20%;
        flex-basis: 20%;
    }
}
